import MailStat from '@mail/stats-sdk';
import utils from './assets/utils';
import Enums from './assets/enums';
import './assets/msgbox';
import config from './config';

if (NODE_ENV === 'production') {
    Raven.config('https://3ebcec1bef5e4c269636835e6ef20c64@fetrack.mail.163.com/10', {
        release: 'webmail_hw_login@' + GLOBAL_VERSION, // 版本号，与发布流程相匹配
        environment: 'production', // 自定义环境，prod|test|t1|t2|dev|staging，与发布流程相匹配
        sampleRate: 0.1 // 前端采样率，随机10%，0.1~1.0
    }).install();
}

var page = {
    isVip: false,
    target: Enums.TARGET_163, // 登录到的邮箱目标
    inited: [], //  已经初始化过的登录
    styleValue: -1, // 邮箱版本
    ssl: true, //  ssl选择
    scope: Enums.DOMAIN_MAIL_HW, //  配置区的scope(默认为hw.mail.163.com)

    init: fInit,
    initTongji: fInitTongji,
    sendStatistics: fSendStatistics,
    initScope: fInitScope,
    redirectToSmart: fRedirectToSmart,

    getStyleOptions: fGetStyleOptions,
    getSslAble: fGetSslAble,
    getUrsConfig: fGetUrsConfig,
    getEntryParma: fGetEntryParma,

    renderLoginArea: fRenderLoginArea,
    bindEvent: fBindEvent,
    switchNav: fSwitchNav,
    initTarget: fInitTarget,
    showPanel: fShowPanel,
    initUrs: fInitUrs,
    instantUrs: fInstantUrs,
    onURSloginSucc: fOnURSloginSucc,
    onAreaInitSucc: fOnAreaInitSucc,
    renderStyleOptions: fRenderStyleOptions,
    renderSSLCheck: fRenderSSLCheck,
    fixMainPosition: fFixMainPosition,
    onEntryLoginErr: fOnEntryLoginErr,
    showErr: fShowErr,
    onMoudelResize: fOnMoudelResize,
    saveLoginInfo: fSaveLoginInfo
};

window.succ = function (_username) {
    page.onURSloginSucc(page.target, _username || '');
}


function fInit(isVip) {
    var that = this;
    that.isVip = isVip;

    if (isVip) {
        Enums.COOKIE_PRE = 'login_vip_';
        that.target = Enums.TARGET_VIP163;
    }

    // 发生统计
    this.initTongji(isVip);

    //  初始化导航目标(修改 this.target)
    this.initTarget();

    // 跳转到智能版登录页
    this.redirectToSmart(isVip);

    // 渲染登录区域
    this.renderLoginArea(isVip);

    // 初始化确定当前页面域
    this.initScope();

    // 初始化导航
    this.switchNav(this.target);

    // 绑定事件
    this.bindEvent();

    // 加载成功即调整登录框高度
    this.fixMainPosition();

    // 对接entry登录失败逻辑(iframe调的top方法)
    window.indexLogin = {};
    indexLogin.showError = function (code) {
        if (code) {
            that.onEntryLoginErr(code);
        }
    }

    // 判断是否有errortype参数，出错误提示
    var errCode = utils.getQuery('errorType');
    if (errCode) {
        that.onEntryLoginErr(errCode);
    }
    
    // 延时设定页面背景大图
    // let __bgDelay = setTimeout(function(){
    //     document.body.style.backgroundImage = `url(${bgPic})`;

    //     clearTimeout(__bgDelay);
    //     __bgDelay = null;
    // }, 300);
}

function fRedirectToSmart (isVip) {
    var aClient = ['ipad', 'iphone os', 'android', 'ucweb', 'rv:1.2.3.4', 'windows ce', 'windows mobile', 'midp'];
    var sUserAgent = navigator.userAgent.toLowerCase();
    
    
    for(var i = 0; i < aClient.length; i++) {
        if (sUserAgent.indexOf(aClient[i]) > -1) {
            var url = 'https://email.163.com/m/';
            if (isVip) {
                url = 'https://vip.163.com/webapp/login.html';
            }
            window.location.replace(url);
            break;
        } else {
            continue;
        }
    }
}

function fInitTongji (isVip) {
    var page = '';
    var project = isVip ? 'vip_webmail_bebcaa2301ddc2a9' : 'free_webmail_9c89159b6fde1dc2';
    var mailStats = null;

	mailStats = new MailStat({
		dataSource: 'mail',
	});

	mailStats.config({
		application: project,
		options: {
			autoAddKeyInfo: false,
		}
	});

	window.mailStats = mailStats;

    if (isVip) {
        page = 'p_login_mail_hw_vip';
    } else {
        page = 'p_login_mail_hw';
    }
    
    if (page) {
        mailStats.pv(page);
    }

    if (window.addEventListener) {
        document.body.addEventListener('click', (e) => {
            var currentEle = e.target;
            while (currentEle && currentEle !== document) {
                var tjKey = currentEle.getAttribute('data-tj-name');
                var tjValue = currentEle.getAttribute('data-tj-value');

                if (tjValue) {
                    try {
                        tjValue = JSON.parse(tjValue);
                    } catch (error) {
                        tjValue = '';
                        console.warn('统计埋点值必须为 JSON 格式');
                    }
                }

                if (tjKey) {
                    mailStats.click(tjKey, tjValue, e);
                }

                currentEle = currentEle.parentNode;
            }
        });
    }
}
/**
     * @method 发送统计的方法
     *
     * @param {string} key 统计的key
     * @param {any} [data={}] 统计的额外数据
     * @memberof BaseLogin
     */
function fSendStatistics (key, data) {
    if (!key) {
        return false;
    }
    window.mailStats.click(key, data);
}


/**
 * 根据当前页面domain，确定并设定域scope（获取配置项）
 * 
 */
function fInitScope() {
    var scope = Enums.DOMAIN_MAIL_HW;

    if (this.isVip) {
        scope = Enums.DOMAIN_MAIL_HW_VIP;
    }

    this.scope = scope;
}



/**
 * 渲染登录区结构 + 广告
 * 
 */
function fRenderLoginArea(isVip) {
    var html = '';
    var list = isVip ? Enums.NAV_LIST_VIP : Enums.NAV_LIST;
    for (var i = 0; i < list.length; i++) {
        html += '<div class="area area-' + list[i] + '" id="panel-' + list[i] + '"><p class="loading"><b class="ico ico-loading"></b> Loading...</p></div>';
    }
    utils.id('panel').insertAdjacentHTML('afterbegin', html);
}

function fBindEvent() {
    var that = this;
    // 主体tab切换
    utils.addEvent(document.getElementById('nav'), "click", function (e) {
        var dom = e.target || e.srcElement;
        while (dom) {
            if (dom.nodeName.toUpperCase() == 'LI' && dom.getAttribute('data-target')) {
                window.event ? window.event.returnValue = false : e.preventDefault();
                var target = dom.getAttribute('data-target');
                if (target && target !== that.target) {
                    that.switchNav(target);

                    (window.googleCookieTips && window.googleCookieTips[target]) && window.googleCookieTips[target]();
                }
                break;
            }
            dom = dom.parentNode;
        }
    });

    // 版本选择select点击
    utils.addEvent(utils.id('styleSelect'), 'click', function (e) {
        var sDisplay = utils.id('styleOptions').style.display;
        utils.id('styleOptions').style.display = (sDisplay) ? '' : 'none';
    })

    //  版本选择滑出隐藏
    utils.mouseleave(utils.id('styleOptions'), function () {
        this.style.display = 'none';
    });




    // 版本选择select点击
    utils.addEvent(utils.id('styleOptions'), 'click', function (e) {
        var dom = e.target || e.srcElement;

        var styleValue = dom.getAttribute('data-style');
        var styleTxt = dom.innerHTML;

        if (styleValue !== null) {
            that.styleValue = styleValue;
            utils.id('styleCurrect').innerHTML = styleTxt;
            utils.id('styleOptions').style.display = 'none';
        }
    });


    // resize时调整登录区位置
    utils.addEvent(window, 'resize', function () {
        that.fixMainPosition();
    })

    // onload时调整登录区位置
    utils.addEvent(window, 'load', function () {
        that.fixMainPosition();
    })
}

/**
 * 切换登录域
 * 
 * @param {string} target 切换目标
 * @param {string} account 预填账号
 */
function fSwitchNav(target, account) {

    // 设定当前target
    this.target = target;

    // 显示标签对应内容
    this.showPanel(account);

    // 初始化版本选择dom
    this.renderStyleOptions();

    // 初始化ssl选择
    this.renderSSLCheck();

    // 将tab信息写入cookie
    utils.setCookie('target', target);
}
/**
 * 初始化ssl选择
 * 
 */
function fRenderSSLCheck() {
    var sslAble = this.getSslAble();

    if (sslAble) {
        // 如果可用ssl，则开放
        utils.id('sslAble').checked = true;
        utils.id('sslAble').disabled = false;
        utils.id('sslAble').parentNode.style.display = '';
    } else {
        utils.id('sslAble').checked = false;
        utils.id('sslAble').disabled = true;
        utils.id('sslAble').parentNode.style.display = 'none';
    }
}

/**
 * 初始化导航目标
 * 导航目标优先级： url hash -> cookie self
 * 
 */
function fInitTarget() {
    var target;
    // 从cookie中取定位
    target = utils.getCookie('target');
    if (target && utils.beValidTarget(target)) {
        page.target = target;
    }


    // 根据hash定位
    var hash = window.location.hash;
    var tmp = hash.split('#')[1];
    if (hash && utils.beValidTarget(tmp)) {
        page.target = tmp;
    }
}


/**
 * 显示登录内容区
 * 
 * @param {string} account 预填账号
 */
function fShowPanel(account) {

    var target = this.target;
    var bodyCls = this.isVip ? 'vip ' : '';
    // 全局设定样式范围
    document.body.className = bodyCls + 'mail-' + this.target;

    // 加载loading
    if (this.inited.indexOf(this.target) > -1) {
        // 已初始化过

        // 如果需要预填账号，就干掉旧的urs实例，再new一个新的
        if (account) {
            try {
                delete this['urs_' + target];
                // 去掉初始化完成的class名控制（显示loading）
                this.initUrs(target, account);
            } catch (e) {}
        }
    } else {
        utils.id('panel-' + target).className = 'area area-' + target;
        // utils.id('panel-' + target).innerHTML = '<p class="loading" id="panelLoading"><b class="ico ico-loading"></b> Loading...</p>';
        
        utils.id('panelLoading').style.display = 'block';
        this.initUrs(target, account);
    }
}
/**
 * 初始化urs组件
 * 
 * @param {string} target
 * @param {string} account 预填账号
 */
 function fInitUrs (target, account) {
    var that = this;
    
    if (window.URS) {
        that.instantUrs(target, account);
        that.sendStatistics('b_urs_load_success', {target: that.target});
    } else {
        that.sendStatistics('b_urs_load_original_error');
        utils.loadBackupMessage('https://webzj.netstatic.net/webzj_cdn101/message4.js', function() {
            that.instantUrs(target, account);
            that.sendStatistics('b_urs_load_success', {target: that.target});
        }, function() {
            that.sendStatistics('b_urs_load_backup_error');
            // message加载失败降级到备份cdn,可以在load事件重新初始化
            utils.loadBackupMessage('https://dl.reg.163.com/webzj/ngx/message.js', function() {
                that.instantUrs(target, account);
                that.sendStatistics('b_urs_load_success', {target: that.target});
            }, function() {
                that.isLoadMessageFail = false;
                that.sendStatistics('b_urs_load_fail', {target: that.target});
            });
        });
    }
}
/**
 * 初始化urs组件
 * 
 * @param {string} target 
 * @param {string} account 预填账号
 */
function fInstantUrs(target, account) {
    var that = this;
    var account = account || '';
    var ursTarget = 'urs_' + target;
    var _config = target ? this.getUrsConfig(target, account) : {};
    var ursPluginId = `init${target || ''}Urs`;
    
    // New: 接入灰度: http://doc.hz.netease.com/pages/viewpage.action?pageId=79987668
    if (target) {
        window.URS && window.URS.setPkidAndPd({
            pkid: _config.promark,
            pd: _config.product,
            mode: 1,
            pathbCallback () {
                // 保存pkid，代表此pikd已经有灰度值，此回调时间不定，
                // 回调回来快就用回调，否者，用定时器初始化 

                if (_delayId) {
                    clearTimeout(_delayId);
                    _delayId = null;
                }

                if (!that[ursPluginId]) {
                    that[ursTarget] = new window.URS(_config);

                    that[ursPluginId] = true;
    
                    // 由于初始化是异步操作，短时间触发可能会多次初始化，故提前标记加载成功
                    that.onAreaInitSucc(target);
                }
            }
        });

        var _delayId = setTimeout(() => {
            if (!that[ursPluginId]) {
                that[ursTarget] = new window.URS(_config);

                that[ursPluginId] = true;

                // 由于初始化是异步操作，短时间触发可能会多次初始化，故提前标记加载成功
                that.onAreaInitSucc(target);
            }
        }, 200);
    }
    
    // Old: 旧方式
    // if (target) {
    //     this['urs_' + target] = new window.URS(_config);
        
    //     // 由于初始化是异步操作，短时间触发可能会多次初始化，故提前标记加载成功
    //     this.onAreaInitSucc(target);
    // }
}



/**
 * urs组件初始化成功
 * 
 * @param {any} target 
 */
function fOnAreaInitSucc(target) {
    utils.id('panel-' + target).className += ' area-inited';
    this.inited.push(target);

    // utils.id('panelLoading').style.display = 'none';
}


/**
 * 初始化版本选择列表
 * 
 */
function fRenderStyleOptions() {
    var target = this.target;
    var wraperDom = utils.id('styleOptions');


    var list = this.getStyleOptions();

    var sHtml = '';
    for (var i = 0; i < list.length; i++) {
        sHtml += '<a href="javascript:void(0)" data-style="' + list[i].value + '">' + list[i].txt + '</a>'
    }

    wraperDom.innerHTML = sHtml;
    wraperDom.style.display = 'none';

    // 恢复版本默认值
    if (list.length === 1) {
        // 如果只有一个选项，则显示下拉option内容
        this.styleValue = list[0].value;
        utils.id('styleCurrect').innerHTML = list[0].txt;
    } else {
        this.styleValue = -1;
        // 恢复select文字
        utils.id('styleCurrect').innerHTML = '默认版本';
    }
}
/**
 * 组件登录成功
 * 
 * @param {any} target 登录目标域
 * @param {any} username 登录账号
 */
function fOnURSloginSucc(target, username) {
    var that = this;
    var ofrom = utils.id('login');

    var styleValue = this.styleValue;
    var sslAble = utils.id('sslAble').checked;
    var target = target || this.target;

    // 设定登录action
    ofrom.action = config.scope[this.scope][target].entry;

    // 构造表单数据
    ofrom.innerHTML = '';
    var parmas = that.getEntryParma(target);
    parmas.style = styleValue;
    parmas.allssl = sslAble;
    parmas.username = username;

    for (var key in parmas) {
        if (parmas.hasOwnProperty(key)) {
            // console.log(key + '----' + parmas[key]);
            ofrom.action += '&' + key + '=' + parmas[key];
            // post无用？
            // utils.formAdd(ofrom,key,parmas[key]);
        }
    }

    ofrom.submit();

    setTimeout(function () {
        that.showErr('Login is slow, please try again later or refresh the page.');
    }, 5000);
}

function fFixMainPosition() {
    var oMain = utils.id('main');
    var winWidth = typeof window.innerWidth == 'undefined' ? document.documentElement.clientWidth : window.innerWidth;
    var winHeight = typeof window.innerHeight == 'undefined' ? document.documentElement.clientHeight : window.innerHeight;

    var top = (winHeight - 45 - 67 - 344) / 2;
    if (top < 85) {
        top = 85
    }
    oMain.style.marginTop = top + 'px';
}
/**
 * entry登录失败 (跳到相关的登录首页)
 * 
 * @param {any} code 失败code
 */
function fOnEntryLoginErr(nCode) {

    var sErrType = '';

    switch (nCode) {
        case 1:
            sErrType = 'noId';
            break;
        case 2:
            sErrType = 'noPhone';
            break;
        case 3:
            sErrType = 'noPw';
            break;
        case 460:
            sErrType = 'inputWrong';
            break;
        case 420:
            sErrType = 'inputWrong';
            break;
        case 422:
            sErrType = 'idLocked';
            break;
            /*case 412:
                sErrType = 'loginWrong';
                nTarget = 0;
                break;*/ //已单独处理
        case 414:
            sErrType = 'loginWrong';
            break;
        case 415:
            sErrType = 'loginWrong';
            break;
        case 416:
            sErrType = 'loginWrong';
            break;
        case 417:
            sErrType = 'loginWrong';
            break;
        case 418:
            sErrType = 'loginWrong';
            break;
        case 419:
            sErrType = 'loginWrong';
            break;
        default:
            sErrType = 'systemBusy';
    }

    if (nCode) {
        this.showErr(config.errorInfo[sErrType].title);
    }
}
/**
 * 显示错误
 * 
 * @param {string} text 错误内容 
 */
function fShowErr(text) {
    utils.id('errTips').innerHTML = text || '登录有误';
    utils.id('errTips').style.display = '';
    setTimeout(function () {
        utils.id('errTips').innerHTML = '';
        utils.id('errTips').style.display = 'none';
    }, 4000);

}


/**
 * 返回可选的邮箱版本列表数据
 * 
 * @returns option数组
 */
function fGetStyleOptions() {
    var list = config.scope[this.scope][this.target].styleOptions;
    return list;
}

/**
 * 返回ssl是否可用配置
 * 
 * @returns ssl选项是否可用
 */
function fGetSslAble() {
    var sslAble = config.scope[this.scope][this.target].sslAble;
    return sslAble;
}
/**
 * 返回urs配置
 * 
 * @param {any} target 
 * @param {any} account 
 * @returns 
 */
function fGetUrsConfig(target, account) {
    var that = this;
    var oConfig = utils.objCopy(config.ursBase || {});
    var scopeConfig = utils.objCopy(config.scope[this.scope][target].ursParma);

    // 默认账号
    if (account.indexOf('@') > 0) {
        scopeConfig.preFilledMail = account;
    } else {
        scopeConfig.preFilledMail = account + scopeConfig.prdomain;
    }

    // 遍历附加属性，增加urs配置
    for (var i in scopeConfig) {
        if (scopeConfig.hasOwnProperty(i)) {
            oConfig[i] = scopeConfig[i];
        }
    }

    if (process.env.NODE_ENV == 'development') {
        console.log(oConfig);
    }

    // urs超时回调
	oConfig['loadTimeout'] = () => {
        that.sendStatistics('b_accountLoginUrsLoad_fail', {target: target});
	}
    
	oConfig['mailLoginErrorCb'] = (res) => {
		that.sendStatistics('b_ursLogin_error', {...res});
	}

    // chromeCookieError 因chrome浏览器的cookie策略变更，导致cookie不可用，需要提示用户
    oConfig['chromeCookieError'] = () => {
        window.googleCookieTips = window.googleCookieTips || {};
        if (target === Enums.TARGET_163) {
            window.googleCookieTips[Enums.TARGET_163] = function () {
                if (that.target !== Enums.TARGET_163) {
                    return;
                }

                that.sendStatistics('b_ursLogin_chromeCookieError', {target: target});
                window.showMsgBox({
                    title: 'Please go to the new website to login to your 163 email account.',
                    body: 'The browser you are using has disabled third-party cookies, resulting in an inability to log in on this page. Please click the button below to go to the 163 email.',
                    btns: [{
                        text: 'Go to the new website',
                        icon: '//mail-activity.nos-jd.163yun.com/c068cb99c2f5cea8763cccf146642478.png',
                        click: function () {
                            window.mailStats.click('b_ursLogin_chromeCookieError_ok', {target: target});
                            window.location.href = 'https://mail.163.com/';
                        }
                    }],
                    close: function () {
                        window.mailStats.click('b_ursLogin_chromeCookieError_close', {target: target});
                    }
                });                    
            };
            window.googleCookieTips[Enums.TARGET_163]();
        } else if (target === Enums.TARGET_126) {
            window.googleCookieTips[Enums.TARGET_126] = function () {
                if (that.target !== Enums.TARGET_126) {
                    return;
                }

                that.sendStatistics('b_ursLogin_chromeCookieError', {target: target});
                window.showMsgBox({
                    title: 'Please go to the new website to login to your 126 email account.',
                    body: 'The browser you are using has disabled third-party cookies, resulting in an inability to log in on this page. Please click the button below to go to the 126 email.',
                    btns: [{
                        text: 'Go to the new website',
                        icon: '//mail-activity.nos-jd.163yun.com/160a05aed21ddf1f544806a639d8156f.png',
                        click: function () {
                            window.mailStats.click('b_ursLogin_chromeCookieError_ok', {target: target});
                            window.location.href = 'https://mail.126.com/';
                        }
                    }],
                    close: function () {
                        window.mailStats.click('b_ursLogin_chromeCookieError_close', {target: target});
                    }
                });                    
            };
            window.googleCookieTips[Enums.TARGET_126]();
        } else if (target === Enums.TARGET_YEAH) {
            window.googleCookieTips[Enums.TARGET_YEAH] = function () {
                if (that.target !== Enums.TARGET_YEAH) {
                    return;
                }

                that.sendStatistics('b_ursLogin_chromeCookieError', {target: target});
                window.showMsgBox({
                    title: 'Please go to the new website to login to your Yeah email account.',
                    body: 'The browser you are using has disabled third-party cookies, resulting in an inability to log in on this page. Please click the button below to go to the Yeah email.',
                    btns: [{
                        text: 'Go to the new website',
                        icon: '//mail-activity.nos-jd.163yun.com/fd351764f3f3f13e1113621d002c6b90.png',
                        click: function () {
                            window.mailStats.click('b_ursLogin_chromeCookieError_ok', {target: target});
                            window.location.href = 'https://mail.yeah.net/';
                        }
                    }],
                    close: function () {
                        window.mailStats.click('b_ursLogin_chromeCookieError_close', {target: target});
                    }
                });
            };
            window.googleCookieTips[Enums.TARGET_YEAH]();
        } else if (target === Enums.TARGET_VIP126) {
            window.googleCookieTips[Enums.TARGET_VIP126] = function () {
                if (that.target !== Enums.TARGET_VIP126) {
                    return;
                }

                that.sendStatistics('b_ursLogin_chromeCookieError', {target: target});
                window.showMsgBox({
                    title: 'Please go to the new website to login to your VIP126 email account.',
                    body: 'The browser you are using has disabled third-party cookies, resulting in an inability to log in on this page. Please click the button below to go to the VIP126 email.',
                    btns: [{
                        text: 'Go to the new website',
                        click: function () {
                            window.mailStats.click('b_ursLogin_chromeCookieError_ok', {target: target});
                            window.location.href = 'https://vip.126.com/';
                        }
                    }],
                    close: function () {
                        window.mailStats.click('b_ursLogin_chromeCookieError_close', {target: target});
                    }
                });
            };
            window.googleCookieTips[Enums.TARGET_VIP126]();
        } else if (target === Enums.TARGET_VIP188) {
            window.googleCookieTips[Enums.TARGET_VIP188] = function () {
                if (that.target !== Enums.TARGET_VIP188) {
                    return;
                }

                that.sendStatistics('b_ursLogin_chromeCookieError', {target: target});
                window.showMsgBox({
                    title: 'Please go to the new website to login to your VIP188 email account.',
                    body: 'The browser you are using has disabled third-party cookies, resulting in an inability to log in on this page. Please click the button below to go to the VIP188 email.',
                    btns: [{
                        text: 'Go to the new website',
                        click: function () {
                            window.mailStats.click('b_ursLogin_chromeCookieError_ok', {target: target});
                            window.location.href = 'https://vip.188.com/';
                        }
                    }],
                    close: function () {
                        window.mailStats.click('b_ursLogin_chromeCookieError_close', {target: target});
                    }
                });
            };
            window.googleCookieTips[Enums.TARGET_VIP188]();
        }
    };

    // 成功回调
    oConfig.logincb = function (_username) {
        that.sendStatistics('b_accountLogin_success', {uid: _username});
        that.onURSloginSucc(target, _username);
        setTimeout(() => {
			that.sendStatistics('b_accountLogin_fail', {uid: _username});
		}, 15000);
    };

    // 初始化成功回调
    oConfig.initReady = function (_username) {
        // 初始化成功为解决异步问题而提前，避免延迟标记初始化成功导致的插入多个组件的问题
        // that.onAreaInitSucc(target);
        utils.id('panelLoading').style.display = 'none';
    };

    // 窗口变化回调
    oConfig.moduleResize = function(_opt) {
        that.onMoudelResize(_opt,target);
    };

    return oConfig;
}


/**
 * 返回entry配置
 * 
 * @param {any} target 
 * @returns entry配置
 */
function fGetEntryParma(target) {
    var that = this;
    var oParma = config.entryBase || {};
    var scopeEntry = config.scope[this.scope][target].entryParma;

    // 遍历附加属性，增加entry配置
    for (var i in scopeEntry) {
        if (scopeEntry.hasOwnProperty(i)) {
            oParma[i] = scopeEntry[i];
        }
    }

    if (window.mailStats && window.mailStats.deviceId) {
        oParma['deviceId'] = window.mailStats.deviceId;
    }
    if (window.mailStats && window.mailStats.commonData && window.mailStats.commonData.sdid) {
        oParma['&sdid'] = window.mailStats.commonData.sdid;
    }

    return oParma;
}

/**
 * urs窗口变化
 * @return {[type]} urs窗口配置
 */
function fOnMoudelResize(_opt,target) {
    var targetEle = utils.id( 'panel-' + target );
    targetEle.style.width = _opt.width + 'px';
    targetEle.style.height = _opt.height + 'px';
}
/**
 * 设置登录信息
 * @return {[type]} 支持mail3登录
 */
function fSaveLoginInfo(options) {
    var gLoginInfo = {};

    gLoginInfo[options.target] = {
        username : options.username,
        style : options.style
    };
    var aCookie = [];
    for(var d in gLoginInfo){
        aCookie.push(d + ";"+ gLoginInfo[d].username +";" + gLoginInfo[d].style);
    }

    utils.setCookie("nts_mail_user", options.username ,true,false);

    utils.setCookie("alllogininfo", aCookie.join("|"), true,false);
    utils.setCookie("alllogindomain", options.target, true,false);
}

function init(type) {
    page.init(type);
}

export default init;